<style lang="scss">
#debug {
  display: none;
}
#frontend {
}
</style>

<template>
  <!--
  <HeaderIntroBoxBrands :style="{ 'background-image': `url(${require('@/assets/img/marketplaces/marketplaces-startbild-v2.jpg')})` }"  headerImageText="WILLHABEN">
  -->
  <SocialMediaIconBox />
  <BrandsBox
    :brandImgLogo="require('@/assets/img/marken/brands/kneipp-verlag.svg')"
    brandAltText="Kneipp Verlag"
    :brandHeadline="$t('kneipp-verlag.hl')"
    :brandLongText="$t('kneipp-verlag.txt')"
    brandGruendung="1986"
    brandWebsite="https://www.styriabooks.at/"
    brandWebsiteText="www.styriabooks.at"
  />
  <MarkenBoxen />
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import HeaderIntroBoxBrands from '../../../components/Frontend/Brands/HeaderIntroBoxBrands.vue';
import BrandsBox from '../../../components/Frontend/Brands/BrandsBox.vue';
import MarkenBoxen from '../../../components/Frontend/MarkenBoxen.vue';
import Footer from '../../../components/Frontend/Footer.vue';

export default defineComponent({
  meta: {
    de: {
      title: 'Unsere Media-Brands | Styria Media Group',
      keywords: ['medien-marken', 'media-brands' , 'kleine zeitung' , 'antenne steiermark' , 'die presse' , 'cope' , 'styria' , 'styria media group'],
      description: 'Von der Printzeitung über das Newsportal bis hin zur Content- und Performance-Agentur: Wir informieren. Wir unterhalten. Wir vernetzen.',
      image: require('@/assets/img/og/brands.png'),
    },
    en: {
      title: 'Our media brands | Styria Media Group.',
      keywords: ['medien-brands', 'media-brands' , 'kleine zeitung' , 'antenne steiermark' , 'die presse' , 'cope' , 'styria' , 'styria media group'],
      description: 'From the print newspaper to the news portal to the content and performance agency: We inform. We entertain. We network.',
      image: require('@/assets/img/og/brands.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderIntroBoxBrands,
    MarkenBoxen,
    BrandsBox,
    Footer,
  },
  setup() {


    return {
     
    };
  },
});
</script>
